<template>
  <action-dispatcher
    action="modules/getDetails"
    :parameters="$route.params.id"
    ref="modulesDispatcher"
    class="view-module"
  >
    <header :style="headerStyle">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4">
            <h1>{{ module.name }}</h1>
            <p class="mt-3" v-html="module.description" />

            <!-- <div class="d-flex align-items-center">
              <div class="progress mr-3">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width: ${progressPercentage}%`"
                ></div>
              </div>
              <small
                ><strong>{{ progressPercentage }}% concluído</strong></small
              >
            </div> -->

            <div v-if="currentLesson" class="py-2">
              <router-link
                class="btn btn-primary px-5"
                :to="{ name: 'lesson', params: { lesson: currentLesson.id } }"
              >
                <i class="icon-play" />
                <span v-if="currentLesson.number === 1">Iniciar</span>
                <span v-else>Continuar</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid pt-5">
      <h4 v-if="module.sub_modules && module.sub_modules.length">
        Submódulos
      </h4>
      <div class="row">
        <div
          class="col-lg-2 pb-4"
          v-for="(module, i) in module.sub_modules"
          :key="i"
        >
          <module-card :module="module" />
        </div>
      </div>

      <action-dispatcher action="lessons/getAll" :parameters="$route.params.id">
        <h4 v-if="lessons && lessons.length">
          Aulas
        </h4>
        <lessons-list class="order-lg-2" :lessons="lessons" />
      </action-dispatcher>
    </div>
  </action-dispatcher>
</template>

<script>
import ModuleCard from "@/components/home/ModuleCard";
import LessonsList from "../components/module/LessonsList";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: { LessonsList, ModuleCard },
  computed: {
    ...mapState("modules", ["module"]),
    ...mapState("lessons", ["lessons"]),
    headerStyle() {
      const colorFrom = this.$setup.theme === "dark" ? "black" : "#393444";
      const colorTo =
        this.$setup.theme === "dark" ? "rgba(0,0,0,0)" : "#39344400";

      const url = get(this.module, "header")
        ? this.module.header
        : get(this.module, "picture");

      return `background-image: linear-gradient(to top, ${colorFrom}, ${colorTo} 50%), linear-gradient(to right, ${colorFrom}, ${colorTo} 50%), url("${url}")`;
    },
    currentLesson() {
      return (
        this.lessons &&
        this.lessons.find(
          lesson => !lesson.progress || !lesson.progress.finished_at
        )
      );
    },
    numLessons() {
      return get(this.module, "lessons.length", 0);
    },
    category() {
      return get(this.module, "lessons[0].category.name", "");
    }
    // progressPercentage() {
    //   return Math.round(
    //     get(
    //       this.$user.user.progress_list.find(
    //         p => p.module_id === parseInt(this.$route.params.id)
    //       ),
    //       "progress"
    //     )
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
.view-module {
  header {
    padding: 90px 0 70px;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    .progress {
      max-width: 200px;
      width: 50%;
      height: 7px;
      border-radius: 7px;

      .progress-bar {
        border-radius: 7px;
      }
    }

    .badge {
      font-size: 14px;
      font-weight: bold;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff;
      color: white;
    }

    p {
      ::v-deep * {
        color: white !important;
      }
    }

    a {
      line-height: 34px;
    }
  }
}
</style>
